@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Fira+Sans:wght@400;500;600;700&display=swap");

@layer base {
  html,
  body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    height: 100%;
    scroll-behavior: smooth;
  }
}

/* Resoponsive sizing */

.containers {
  width: 92%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.font-firaSans {
  font-family: "Fira Sans", sans-serif;
}
/* Text shadow */

.text-shadow {
  text-shadow: 0em 0em 0.3em rgb(0 0 0 / 40%);
}

/* Scroller */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 40px;
}

.horizontal-image-slider img {
  animation: horizontal 40s linear infinite alternate;
  width: 5000px;
}

@keyframes horizontal {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translateX(100px, 0);
  }

  100% {
    transform: translate(-2000px, 0);
  }
}

.vertical-image-slide span {
  animation: nudge 10s linear infinite alternate;
}
.vertical-image-slide_2 span {
  animation: nudge 10s linear infinite alternate;
}

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translateX(0, 70%);
  }

  100% {
    transform: translate(0, -70%);
  }
}
.fade-out-up {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    white 75%,
    white 100%
  );
}
.fade-out-down {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    white 75%,
    white 100%
  );
}

button:focus-visible {
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.Input-number input[type="number"]::-webkit-inner-spin-button,
.Input-number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
  opacity: 1 !important;
}

.react-datepicker-wrapper {
  width: fit-content;
}

.reports_slider .slick-dots {
  position: unset !important;
}
